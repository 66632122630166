@import url("https://fonts.googleapis.com/css?family=Nunito");

$default-height: 75%;
$default-width: 37%;
$level2-height: $default-height - 8%;
$level2-width: $default-width - 8%;
$level1-height: $default-height - 2%;
$level1-width: $default-width - 2%;
$level0-height: $default-height + 5%;
$level0-width: $default-width + 5%;
$level-2-left: 15%;
$level-1-left: 30%;
$level0-left: 50%;
$level1-left: 70%;
$level2-left: 85%;
$level-2-bs: -0.1em 0.1em 0.2em #212121;
$level-1-bs: -0.2em 0.2em 0.4em #212121;
$level2-bs: 0.1em 0.1em 0.2em #212121;
$level1-bs: 0.2em 0.2em 0.4em #212121;
$level0-bs: 0 0.4em 0.8em #212121;

body {
  background-color: #556b2f;
  font-family: "Nunito";
  overflow-x: hidden;
}

.basedOn {
  text-align: center;
  font-size: 1.2em;
  color: #fff;
  a,
  a:visited,
  a:hover,
  a:active {
    color: #628720;
  }
}

#carousel {
  height: 100%;
  width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.arrow {
  position: absolute;
  width: 4%;
  height: 60%;
  background-color: #556b2f;
  text-align: center;
  font-size: 2em;
  cursor: pointer;
  line-height: 30px;
  z-index: 1000;
  top: 50%;
  transform: translate(0, -50%);
  i {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
  }
  transition: all 500ms;
  &-right {
    right: 0;
  }

  &-left {
    left: 0;
  }
  &:hover {
    background-color: #323130;
    color: #ffeb3b;
  }
}

.item {
  text-align: center;
  color: white;
  font-size: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: box-shadow 1s, height 250ms, width 250ms, left 1s, margin-top 1s,
    line-height 250ms, background-color 1s;
}

.level-2 {
  box-shadow: $level-2-bs;
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  background-color: #228291;
  left: $level-2-left;
}

.level-1 {
  box-shadow: $level-1-bs;
  height: $level1-height;
  width: $level1-width;
  line-height: $level1-height;
  background-color: #6796e5;
  left: $level-1-left;
}

.level0 {
  box-shadow: $level0-bs;
  height: $level0-height;
  width: $level0-width;
  line-height: $level0-height;
  background-color: #4ec9e1;
  left: $level0-left;
}

.level1 {
  box-shadow: $level1-bs;
  height: $level1-height;
  width: $level1-width;
  line-height: $level1-height;
  background-color: #6796e5;
  left: $level1-left;
}

.level2 {
  box-shadow: $level2-bs;
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  background-color: #228291;
  left: $level2-left;
}

.level-2,
.level2 {
  z-index: 0;
}

.level-1,
.level1 {
  z-index: 1;
}

.level0 {
  z-index: 2;
}

.left-enter {
  opacity: 0;
  left: $level2-left;
  height: $level2-height - $default-height;
  width: $level2-width - $default-width;
  line-height: $level2-height - 30;

  &.left-enter-active {
    opacity: 1;
    left: $level2-left;
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    transition: box-shadow 1s, left 1s, opacity 1s, height 250ms, width 250ms,
      margin-top 1s, line-height 1s;
  }
}

.left-leave {
  opacity: 1;
  left: $level-2-left;
  height: $level2-height;
  width: $level2-width;
  line-height: $level2-height;
  z-index: -1;

  &.left-leave-active {
    z-index: -1;
    left: $level-2-left;
    opacity: 0;
    height: $level2-height - $default-height;
    width: $level2-width - $default-width;
    line-height: 120px;
    transition: box-shadow 1s, left 1s, opacity 1s, height 250ms, width 250ms,
      margin-top 1s, line-height 1s;
  }
}

.right-enter {
  opacity: 0;
  left: $level-2-left;
  height: $level2-height - $default-height;
  width: $level2-width - $default-width;
  line-height: $level2-height - 30;

  &.right-enter-active {
    left: $level-2-left;
    opacity: 1;
    height: $level2-height;
    line-height: $level2-height;
    width: $level2-width;
    transition: box-shadow 1s, left 1s, opacity 1s, height 250ms, width 250ms,
      margin-top 1s, line-height 1s;
  }
}

.right-leave {
  left: $level2-left;
  height: $level2-height;
  opacity: 1;
  line-height: $level2-height;
  width: $level2-width;

  &.right-leave-active {
    left: $level2-left;
    opacity: 0;
    height: $level2-height - $default-height;
    width: $level2-width - $default-width;
    line-height: $level2-height - 30;
    transition: box-shadow 1s, left 1s, opacity 1s, height 250ms, width 250ms,
      margin-top 1s, line-height 1s;
  }
}

.noselect {
  -webkit-user-select: none;
  -html-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* \chicken-website\src\Pages\Home.css */

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.item1 {
  height: 100vh;
}

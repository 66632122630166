/* \chicken-website\src\Pages\RatingForm.css */

.rating-container {
  text-align: center;
  color: white;
  display: flex;
}

.NotLoggedIn {
  background-color: #556b2f;
  font-size: 26px;
  text-align: center;
  cursor: pointer;
  color: white;
  border: none;
}
.NotLoggedIn:hover {
  color: #bbc3ab;
}

.errormessage {
  color: rgb(243, 0, 0);

  font-size: 26px;
}

.star-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.star {
  background-color: transparent;
  cursor: pointer;
  font-size: 50px;
  color: grey;
}

.star.on {
  color: gold;
}

.lables {
  margin: 15px;
  font-size: 30px;
  text-align: center;
}

.submit {
  font-size: 20px;
  margin: 15px;
  color: white;
}

.submit:hover {
  color: #bbc3ab;
}

textarea {
  font-size: 20px;
  border-radius: 20px;
  border: 1px solid #fff5e1;
  padding: 20px;
}

.border {
  border: 1px solid #fff5e1;
  padding: 5rem;
  border-radius: 10px;
  margin: 0.5rem;
}
form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
